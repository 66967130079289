<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import vueDebounce from 'vue-debounce';

  import axios from 'axios';

  Vue.use(vueDebounce);



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'search',

    props: [
      'placeholder',
      'language'
    ],

    created() {
      this.$root.$on('hideSearch', () => {
        this.hidden = true;
      });
    },

    data() {
      return {
        searchCriteria: null,

        searchResult: {
          strings: null,

          categories: [],

          products: []
        },

        searching: false,
        searchCompleted: false,
        hidden: true,
      }
    },

    computed: {
      searchState() {
        if (!this.searchCompleted) {
          return 'empty';
        }

        if (this.searchResult.categories.length == 0 && this.searchResult.products.length == 0) {
          return 'no_results';
        }

        return 'results';
      },

      noResultsText() {
        if (this.searchResult.strings == null) {
          return '';
        }

        return this.searchResult.strings.no_results_placeholder;
      },

      categoriesTitle() {
        if (this.searchResult.strings == null) {
          return '';
        }

        return this.searchResult.strings.categories_title;
      },

      productsTitle() {
        if (this.searchResult.strings == null) {
          return '';
        }

        return this.searchResult.strings.products_title;
      },

      allProductsButton() {
        if (this.searchResult.strings == null) {
          return '';
        }

        return this.searchResult.strings.products_show_all + ' (' + this.searchResult.products.length + ')';
      },

      productsSearchLink() {
        if (this.language == 'sv') {
          return '/search/products?search=' + this.searchCriteria;
        }

        return '/en/search/products?search=' + this.searchCriteria;
      },

      productArticleNoText() {
        if (this.searchResult.strings == null) {
          return '';
        }

        return this.searchResult.strings.article_no;
      },
    },

    methods: {
      onSubmit() {
        this.executeSearch(true);
      },

      executeSearch(submitted = false) {
        var self = this;

        if (this.searchCriteria == '') {
          this.searchCriteria = null;
          self.searching = false;
          self.searchCompleted = false;
          self.hidden = true;
          return;
        }

        this.searching = true;
        this.searchCompleted = false;

        axios.post('/api/v1/search', {
          language: this.language,
          search_criteria: this.searchCriteria
        })
        .then(function (response) {
          self.searchResult = response.data;

          if (submitted === true && self.searchResult.products.length == 1) {
            let product = self.searchResult.products[0];

            window.location = (product.product_url);
          } else if (submitted === true && self.searchResult.products.length > 1) {
            window.location = (self.productsSearchLink);
          } else {
            self.searching = false;
            self.searchCompleted = true;
            self.hidden = false;
          }
        })
        .catch(function (error) {
          self.searching = false;
          self.searchCompleted = true;
          self.hidden = true;

          console.log("Error searching: ", error);
        });
      },

      clearSearch() {
        this.searchCriteria = null;
        this.searchCompleted = false;
        self.hidden = true;

        this.searchResult.categories = [];
        this.searchResult.products = [];
      },

      receivedFocus() {
        setTimeout(() => {  this.hidden = false; }, 200);
      }
    }
  };
</script>

<template>
  <div class="search">
    <form @submit.prevent="onSubmit">
      <input type="text" @focus="receivedFocus" v-model="searchCriteria" :placeholder="placeholder" v-debounce:500.lock="executeSearch" class="product-search float-none float-md-end float-lg-end">
    </form>

    <div v-if="searchCriteria != null" @click="clearSearch">
      <p class="close-button">X</p>
    </div>

    <div v-if="searchState == 'no_results' && !hidden" class="search-no-result-box">
      <p>{{ noResultsText }}</p>
    </div>

    <div v-if="searchState == 'results' && !hidden" class="search-result-box">
      <div v-if="searchResult.categories.length > 0">
        <h5 class="searchHeader">{{ categoriesTitle }}</h5>

        <ul class="cat-list" v-for="category in searchResult.categories">
          <li>
            <a :href="category.url">
              <span class="cat-name">{{ category.name }}</span><br>
              <span class="cat-bread">{{ category.breadcrumbs }}</span>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="searchResult.products.length > 0  && !hidden">
        <h5 class="searchHeader">{{ productsTitle }}</h5>

        <ul class="prod-search-list" v-if="searchResult.products.length > 5" v-for="index in 5">
          <li>
            <a :href="searchResult.products[index].product_url">
								<div class="row">
									<div class="col-3">
										<img :src="searchResult.products[index].image_url">
									</div>
									<div class="col-9">
										<span class="prod-title">{{ searchResult.products[index].name }}</span><br>
              			<span class="prod-sku">{{ productArticleNoText }} {{ searchResult.products[index].visma_product_no }}</span>
									</div>
								</div>
            </a>
          </li>
        </ul>

        <ul class="prod-search-list" v-if="searchResult.products.length <= 5" v-for="product in searchResult.products">
          <li>
            <a :href="product.product_url">
							<div class="row">
								<div class="col-3">
									<img :src="product.image_url">
								</div>
								<div class="col-9">
									<span class="prod-title">{{ product.name }}</span><br>
              		<span class="prod-sku">{{ productArticleNoText }} {{ product.visma_product_no }}</span>
								</div>
							</div>
            </a>
          </li>
        </ul>

        <a class="search-view-all" v-if="searchResult.products.length > 5" :href="productsSearchLink">{{ allProductsButton }}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  /*
  |--------------------------------------------------------------------------
  | Search
  |--------------------------------------------------------------------------
  */

  .search {
    position: relative;
  }

  .search-no-result-box {
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;

    padding: 5px;

    background-color: #fff;
    border: 1px solid #ccc;

    z-index: 10000;
  }

  .search-result-box {
    position: absolute;
    top: 40px;
    left: 0px;
    right: 0px;

    padding: 5px;

    background-color: #fff;
    border: 1px solid #ccc;

    z-index: 10000;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;

    color: #000;

    cursor: pointer;
  }
</style>
