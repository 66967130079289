<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import vueDebounce from 'vue-debounce';

  import axios from 'axios';
  import Swal from 'sweetalert2';

  import ProductRow from './components/ProductRow';

  Vue.use(vueDebounce);



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'my-quotes',

    components: {
      ProductRow
    },

    props: [
      'language'
    ],

    created() {
      if (localStorage.product_requests == null) {
        this.currentProducts = [];
      } else {
        this.currentProducts = JSON.parse(localStorage.product_requests);

        if (!Array.isArray(this.currentProducts)) {
          this.currentProducts = [this.currentProducts];
        }
      }
    },

    mounted() {
      if (this.currentProducts.length > 0) {
        this.fetchProducts();
      }
    },

    data() {
      return {
        currentProducts: [],

        products: [],

        name: null,
        email: null,
        message: null
      }
    },

    computed: {
      hasProducts() {
        return (this.currentProducts.length > 0);
      },

      titleText() {
        if (this.language == 'sv') {
          return 'Förfrågan på produkter';
        }

        return 'Request a quote';
      },

      emptyText() {
        if (this.language == 'sv') {
          return 'Inga produkter i listan';
        }

        return 'Your list is empty';
      },

      sendTitle() {
        if (this.language == 'sv') {
          return 'Skicka er förfrågan';
        }

        return 'Send the request';
      },

      nameLabel() {
        if (this.language == 'sv') {
          return 'Namn';
        }

        return 'Name';
      },

      emailLabel() {
        if (this.language == 'sv') {
          return 'E-post';
        }

        return 'Email';
      },

      messageLabel() {
        if (this.language == 'sv') {
          return 'Meddelande';
        }

        return 'Message';
      },

      messagePlaceholder() {
        if (this.language == 'sv') {
          return 'Noteringar för din förfrågan';
        }

        return 'Notes on your request';
      },

      sendButton() {
        if (this.language == 'sv') {
          return 'Skicka din förfrågan';
        }

        return 'Send your request';
      },

      requestSentMessage() {
        if (this.language == 'sv') {
          return 'Din förfrågan har skickats.';
        }

        return 'Your request has been sent.';
      },
    },

    methods: {
       fetchProducts() {
        var self = this;

        axios.post('/api/v1/quote_products', {
          product_ids: this.currentProducts
        })
        .then(function (response) {
          self.products = response.data.data;
        })
        .catch(function (error) {
          console.log("Error fetching products: ", error);
        });
      },

      removeProduct(productID) {
        var index = this.currentProducts.indexOf(productID);
        if (index !== -1) {
          this.currentProducts.splice(index, 1);

          localStorage.product_requests = JSON.stringify(this.currentProducts);

          this.fetchProducts();
        }
      },

      sendRequest() {
        var self = this;

        axios.post('/api/v1/send_quote_request', {
          product_ids: this.currentProducts,
          name: this.name,
          email: this.email,
          message: this.message
        })
        .then(function (response) {
          Swal.fire({
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            title: self.requestSentMessage,
            willClose: () => {
              self.name = null;
              self.email = null;
              self.message = null;

              localStorage.removeItem("product_requests");
              self.currentProducts = [];
              self.products = [];
            }
          });
        })
        .catch(function (error) {
          console.log("Error fetching products: ", error);
        });
      },
    }
  };
</script>

<template>
  <div class="container pt-80 pb-80">
    <div class="row">
			<div class="col-12" v-if="!hasProducts">
				<h1>{{ titleText }}</h1>
				<p>{{ emptyText }}</p>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
		    <div v-if="hasProducts">
    		  <product-row
        		v-for="product in products"
        		:product="product"
        		:key="product.id"
        		:language="language"
        		@removeProduct="removeProduct"
      		></product-row>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
      		<p>{{ sendTitle }}</p>

      		<label for="name" class="form-label">{{ nameLabel }}</label>
      		<input v-model="name" type="text" class="form-control" id="name" aria-describedby="emailHelp">

		      <label for="email" class="form-label">{{ emailLabel }}</label>
    		  <input v-model="email" type="email" class="form-control" id="email" aria-describedby="emailHelp">

		      <label for="message" class="form-label">{{ messageLabel }}</label>
    		  <textarea v-model="message" class="form-control" id="message" rows="3"></textarea>

		      <button @click="sendRequest" type="button" class="btn btn-outline-dark mt-5 float-end">{{ sendButton }}</button>
				</div>
			</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  /*
  |--------------------------------------------------------------------------
  | My Quotes
  |--------------------------------------------------------------------------
  */


</style>
