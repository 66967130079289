/*
|--------------------------------------------------------------------------
| Dependency Definitions
|--------------------------------------------------------------------------
*/

require('./bootstrap');

require('alpinejs');

import Vue from 'vue';



/*
|--------------------------------------------------------------------------
| Global Components
|--------------------------------------------------------------------------
*/

Vue.component('search', require('./search/Search').default);
Vue.component('my-quotes', require('./requests/components/MyQuotes').default);



/*
|--------------------------------------------------------------------------
| Application
|--------------------------------------------------------------------------
*/

let app = new Vue({
  el: '#app-root',
});

// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the header
var header = document.getElementById("header-main");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}
