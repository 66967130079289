<script>

  /*
  |--------------------------------------------------------------------------
  | Dependency Definitions
  |--------------------------------------------------------------------------
  */

  import Vue from 'vue';
  import vueDebounce from 'vue-debounce';

  import axios from 'axios';
  import Swal from 'sweetalert2';

  Vue.use(vueDebounce);



  /*
  |--------------------------------------------------------------------------
  | Component Implementation
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'product-row',

    props: [
      'product',
      'language'
    ],

    data() {
      return {
      }
    },

    computed: {
      localizedName() {
        return this.product['name_' + this.language];
      },
    },

    methods: {
      removeProduct() {
        this.$emit('removeProduct', this.product.id);
      }
    }
  };
</script>

<template>
  <div>
		<div class="card p-5 mb-5">
			 <div class="row align-items-center">
				<div class="col-12 col-md-4 col-lg-4">
					<p class="remove" @click="removeProduct">X</p>
					<img :src="product.image_url">
				</div>
				<div class="col-12 col-md-8 col-lg-8">
					<p>{{ localizedName }}</p>
				</div>
			</div>
		</div>
  </div>
</template>

<style lang="scss" scoped>

  /*
  |--------------------------------------------------------------------------
  | Product Row
  |--------------------------------------------------------------------------
  */
</style>
